/**
 * @ngdoc directive
 * @name usersListActions
 * @module flowingly.runner.setup
 * @description This component is used to display actions button list in a users row
 * @usage
 * ```
     <users-list-actions user-id='dataItem.id'></users-list-actions>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * userId: id of user to act upon
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';
import angular, { IScope } from 'angular';

angular
  .module('flowingly.runner.maintenance')
  .component('maintenanceUserListActions', {
    bindings: {
      user: '<',
      onUserUpdated: '&'
    },
    templateUrl:
      'Client/runner.maintenance/users/runner.maintenance.user.list.actions.tmpl.html',
    controller: [
      '$scope',
      'dialogService',
      'permissionsService',
      'sessionService',
      'setupDeleteApiService',
      function (
        $scope: IScope,
        dialogService: SharedAngular.DialogService,
        permissionsService: SharedAngular.PermissionsService,
        sessionService,
        setupDeleteApiService
      ) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const $ctrl = this;

        $ctrl.editUser = editUser;
        $ctrl.deleteUser = deleteUser;
        $ctrl.hasActionsPermission =
          permissionsService.currentUserHasAnyOfPermissions([
            FlowinglyPermissions.ADMIN_ACTIONS,
            FlowinglyPermissions.ADMIN_ACTIONS_USER
          ]);

        $ctrl.isUserDeleted = () => $ctrl.user?.delete === true;

        function editUser() {
          dialogService
            .showDialog({
              template:
                'Client/runner.maintenance/users/runner.maintenance.user.detail.tmpl.html',
              controller: 'maintenanceUserDetailController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: $ctrl.user
            })
            .then(function (result) {
              if (
                result === false ||
                (result !== undefined &&
                  dialogService.isCloseModalWithCancelAction(result))
              ) {
                return;
              }
              $ctrl.onUserUpdated();
            });
        }

        function deleteUser() {
          const currentUserEmail = sessionService.getUser().email;
          const userToDelete = {
            email: $ctrl.user.email,
            id: $ctrl.user.id,
            fullName: $ctrl.user.fullName,
            isManager: $ctrl.user.isManager
          };
          setupDeleteApiService.tryDeleteUser(
            userToDelete,
            currentUserEmail,
            $scope,
            $ctrl.onUserUpdated
          );
        }
      }
    ]
  });
