import { SharedAngular } from '@Client/@types/sharedAngular';
import ISettingDetail from '@Shared.Angular/@types/core/contracts/config/ISettingDetail';
import ISettingOverrideDetail from '@Shared.Angular/@types/core/contracts/config/ISettingOverrideDetail';
import ISettingTagDetail from '@Shared.Angular/@types/core/contracts/config/ISettingTagDetail';
import { IConfirmDialogOptions } from '@Shared.Angular/flowingly.services/dialog.service';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';
import angular, { IScope } from 'angular';
import { IStateProvider } from 'angular-ui-router';

angular.module('flowingly.runner.maintenance').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.maintenance.applicationsettings', {
    url: '/applicationsettings',
    params: { title: 'Maintenance - Application Settings' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/applicationsettings/runner.maintenance.applicationsettings.tmpl.html',
        controllerAs: '$ctrl',
        controller: [
          '$scope',
          'maintenanceService',
          'dialogService',
          'notificationService',
          'permissionsService',
          function (
            $scope: IScope,
            maintenanceService: MaintenanceService,
            dialogService: SharedAngular.DialogService,
            notificationService: SharedAngular.NotificationService,
            permissionsService: SharedAngular.PermissionsService
          ) {
            const scrollOptions: ScrollIntoViewOptions = {
              behavior: 'smooth',
              block: 'center'
            };

            this.settingTypes = ['Application', 'Business', 'User'];
            this.hasActionsPermission =
              permissionsService.currentUserHasAnyOfPermissions([
                FlowinglyPermissions.ADMIN_ACTIONS,
                FlowinglyPermissions.ADMIN_ACTIONS_APPLICATION_SETTING
              ]);

            this.loadSettings = () => {
              this.loading = true;
              maintenanceService.getApplicationSettings().then((settings) => {
                this.settings = settings.sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
                this.tagNames = Array.from(
                  new Set(
                    settings.flatMap((setting) =>
                      setting.tags.map((t) => t.name)
                    )
                  )
                ).sort();
                this.setting = { value: '', tags: [] } as ISettingDetail;
                this.deletedOverrides = [];
                this.loading = false;
              });
            };
            this.loadSettings();

            this.clearCachedSettings = () => {
              maintenanceService.clearCachedSettings().then((wasSuccess) => {
                if (wasSuccess) {
                  notificationService.showSuccessToast(
                    'Cached Settings cleared.'
                  );
                } else {
                  notificationService.showErrorToast(
                    'Error clearing cached Settings.'
                  );
                }
              });
            };

            this.editSetting = (setting: ISettingDetail) => {
              this.setting = angular.copy(setting);
              this.deletedOverrides = [];
              const settingValueInput = document.getElementById(
                'setting-value-input'
              ) as HTMLInputElement;
              settingValueInput.scrollIntoView(scrollOptions);
              settingValueInput.select();
            };

            this.updateSetting = (setting: ISettingDetail) => {
              this.loading = true;
              const dialogOptions: IConfirmDialogOptions = {
                title: 'Add/Update Application Setting?',
                message:
                  'Application Settings affect all Businesses that do not have an overriding Business Setting, are you sure?'
              };
              dialogService
                .showConfirmDialog($scope, dialogOptions)
                .then(() => {
                  return maintenanceService.putApplicationSetting(setting);
                })
                .then(() => {
                  notificationService.showSuccessToast(
                    `Application Setting '${this.setting.name}' was updated.`
                  );
                  this.loadSettings();
                })
                .catch((e) => {
                  this.loading = false;
                  if (e?.status && !e?.data?.message) {
                    notificationService.showErrorToast(
                      `Unexpected error occurred updating '${this.setting.name}'`
                    );
                  }
                });
            };

            this.deleteSetting = (settingName: string) => {
              this.loading = true;
              const dialogOptions: IConfirmDialogOptions = {
                title: `Delete '${settingName}'?`,
                message:
                  'Application Settings must not be deleted if there is any code still referencing them. ' +
                  (this.setting.type === 'Business'
                    ? 'If there are any overriding Business Settings they will be deleted too. '
                    : '') +
                  'Are you sure?',
                actionButtonClass: 'red',
                confirmationWord: 'DELETE',
                disabledSeconds: 5
              };
              dialogService
                .showConfirmDialog($scope, dialogOptions)
                .then(() => {
                  return maintenanceService.deleteApplicationSetting(
                    settingName
                  );
                })
                .then(() => {
                  notificationService.showSuccessToast(
                    `Application Setting '${this.setting.name}' was deleted.`
                  );
                  this.loadSettings();
                })
                .catch((e) => {
                  this.loading = false;
                  if (e?.status && !e?.data?.message) {
                    notificationService.showErrorToast(
                      `Unexpected error occurred updating '${this.setting.name}'`
                    );
                  }
                });
            };

            this.deleteOverride = (override: ISettingOverrideDetail) => {
              const overrideIndex = this.setting.overrides.findIndex(
                (o) => o.id === override.id
              );
              const deletedOverride = this.setting.overrides.splice(
                overrideIndex,
                1
              );
              this.deletedOverrides.push(...deletedOverride);
            };

            this.restoreOverride = (override: ISettingOverrideDetail) => {
              const overrideIndex = this.deletedOverrides.findIndex(
                (o) => o.id === override.id
              );
              const restoredOverride = this.deletedOverrides.splice(
                overrideIndex,
                1
              );
              this.setting.overrides.push(...restoredOverride);
            };

            this.addTag = (tagName: string) => {
              if (this.setting.tags.some((t) => t.name === tagName)) {
                return;
              }
              this.setting.tags.push({ name: tagName });
            };

            this.removeTag = (tag: ISettingTagDetail) => {
              this.setting.tags = this.setting.tags.filter((t) => t !== tag);
            };

            this.replaceTag = (tag: string, newTag: string) => {
              if (
                typeof newTag !== 'string' ||
                this.setting.tags.some((t) => t.name === newTag)
              ) {
                return;
              }
              const index = this.setting.tags.findIndex((t) => t.name === tag);
              if (index >= 0) {
                this.setting.tags[index] = { name: newTag };
                this.newTag = null;
                this.selectedTag = null;
              }
            };

            this.filterByTag = (setting: ISettingDetail) => {
              return (
                !this.tagFilter ||
                setting.tags.some(
                  (tag) => tag.name.indexOf(this.tagFilter) > -1
                )
              );
            };
          }
        ]
      }
    }
  });
}
