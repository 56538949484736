import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import { Guid } from '@Shared.Angular/@types/guid';
import angular, { IController, IScope } from 'angular';

enum ReportInputType {
  DATE = 'date'
}

interface IReportInput {
  type: ReportInputType;
  id: string;
  name: string;
  value?: any;
}

interface IReport {
  name: string;
  description: string;
  inputs: IReportInput[];
}

class MaintenanceReportsController implements IController {
  private requestIsPending: boolean;
  private businessId: Guid;
  private reportData: any;
  private selectedReport: IReport;
  private reports: IReport[];

  constructor(
    private maintenanceService: MaintenanceService,
    private $scope: IScope,
    private momentService: Moment
  ) {
    this.reports = [
      {
        name: 'Flow Status',
        description:
          'Get the status of all flows started in the specified local timeframe, ordered by Flow Model and starting User',
        inputs: [
          {
            type: ReportInputType.DATE,
            id: 'createdFrom',
            name: 'Flow started date >='
          },
          {
            type: ReportInputType.DATE,
            id: 'createdTo',
            name: 'Flow started date <',
            value: this.getTomorrowDate()
          }
        ]
      }
    ];
    this.selectedReport = this.reports[0];
    this.$scope.$parent.$watch(
      '$ctrl.selectedBusiness',
      (selectedBusiness: IBusinessDetail) => {
        this.businessId = selectedBusiness?.id;
        this.reportData = null;
      }
    );
  }

  getTomorrowDate() {
    return this.momentService().add(1, 'days').startOf('day').toDate();
  }

  getReport() {
    if (!this.businessId) {
      return;
    }
    this.requestIsPending = true;
    this.reportData = null;
    const params = this.getReportParams(this.selectedReport);
    let request;
    if (this.selectedReport.name === 'Flow Status') {
      request = this.maintenanceService
        .getFlowStatusReport(params)
        .then((data) => {
          this.reportData = data;
          this.requestIsPending = false;
        });
    } else {
      throw new Error('Unhandled report ' + this.selectedReport.name);
    }
    request.catch(() => {
      this.requestIsPending = false;
    });
  }

  private getReportParams(report: IReport) {
    const request = {
      businessId: this.businessId
    } as object;
    for (const input of report.inputs) {
      if (input.type === ReportInputType.DATE) {
        request[input.id + 'Utc'] = this.momentService(input.value)
          .utc()
          .format(this.momentService.defaultFormatUtc);
      } else {
        throw new Error('Unhandled report input type ' + input.type);
      }
    }
    return request;
  }
}

MaintenanceReportsController.$inject = [
  'maintenanceService',
  '$scope',
  'momentService'
];
angular
  .module('flowingly.runner.maintenance')
  .controller('maintenanceReportsController', MaintenanceReportsController);
