/**
 * @ngdoc directive
 * @name categoryList
 * @module flowingly.runner.setup
 * @description  This component is used to display the list of categories
 * @usage
 * ```
     <category-list categories="$ctrl.categories"></category-list>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * * categories: the lsit of available categories (JSON[])
 */
angular.module('flowingly.runner.setup').component('categoryList', {
  bindings: {
    categories: '<',
    onCategoriesUpdated: '&'
  },
  templateUrl:
    'Client/runner.setup/runner.setup.categories/runner.setup.category.list.tmpl.html',
  controller: [
    '$scope',
    'APP_CONFIG',
    'sessionService',
    'setupApiService',
    'flowListManager',
    function (
      $scope,
      APP_CONFIG,
      sessionService,
      setupApiService,
      flowListManager
    ) {
      let $ctrl = this;

      let isFlowModelAdmin = sessionService.isFlowModelAdmin();
      $ctrl.hideActions =
        !!APP_CONFIG.disableAdminCategoryEdit && isFlowModelAdmin;

      $scope.$watch(
        function () {
          return APP_CONFIG.disableAdminCategoryEdit;
        },
        function (value) {
          $ctrl.hideActions = !!value && isFlowModelAdmin;
          if ($ctrl.hideActions) $scope.categoryGrid.hideColumn('actions');
          else $scope.categoryGrid.showColumn('actions');
        }
      );

      $ctrl.options = {
        dataSource: {
          data: $ctrl.categories,
          pageSize: APP_CONFIG.gridReportPageSize,
          sort: {
            field: 'name',
            dir: 'asc'
          }
        },
        noRecords: {
          template: '<category-no-results></category-no-results>'
        },
        scrollable: true,
        pageable: true,
        sortable: true,
        resizable: true,
        filterable: {
          mode: 'row'
        },
        columns: getColumns()
      };

      $ctrl.$onChanges = function (changes) {
        if (!changes.categories.isFirstChange()) updateGrid();
      };

      $ctrl.handleCategoriesUpdated = (deleted) => {
        updateGrid(deleted);
      };

      $ctrl.gridInitDone = true;

      /// PRIVATE METHODS //////////////////////////////////

      function getColumns() {
        //Note: field needs to match the name (and case) of the returned data
        return [
          {
            field: 'name',
            title: 'Name',
            filterable: {
              cell: {
                operator: 'contains', //default filtering uses 'equal to' operator, we want to use contains.
                showOperators: false,
                template: function (args) {
                  args.element.kendoAutoComplete({
                    dataSource: new kendo.data.DataSource({
                      data: $ctrl.categories
                    }),
                    dataTextField: 'name', //enables filtering on specified column
                    valuePrimitive: true,
                    placeholder: 'Search by Name', //placeholder for text input
                    filter: 'contains' //default autocomplete uses 'starts with'
                  });
                }
              }
            }
          },
          {
            field: 'description',
            title: 'Description',
            filterable: false
          },
          {
            field: 'numberOfFlows',
            title: 'Number of Flow Models',
            filterable: false
          },
          {
            field: 'approverName',
            title: 'Approver',
            filterable: false,
            hidden: !APP_CONFIG.enableProcessApprovals
          },
          {
            field: 'actions',
            title: 'Actions',
            width: '120px',
            filterable: false,
            hidden: $ctrl.hideActions,
            template:
              "<category-list-actions category='dataItem' on-categories-updated='$ctrl.handleCategoriesUpdated(deleted)'></category-list-actions>"
          }
        ];
      }

      function updateGrid(deleted) {
        setupApiService.getCategories().then(function (categories) {
          angular.copy(categories, $ctrl.categories);
          $scope.categoryGrid.dataSource.data($ctrl.categories);
          $ctrl.gridNeedResize = true;
        });

        if (deleted !== undefined) {
          //the user just deleted something refresh all views
          //we could go through all data, find and replace. But this could be expensive and they may never view this data.
          //instead we clear local data so next time page loads, data refreshes
          flowListManager.clearLists();
        }
      }
    }
  ]
});
