import angular, {
  IController,
  IComponentOptions,
  ITimeoutService
} from 'angular';
import { WorkflowFinaliseReason } from '@Shared.Angular/@types/core/contracts/queryModel/flows/workflowFinaliseReason';
import IFlowStatusReport from '@Shared.Angular/@types/core/contracts/queryModel/reports/maintenance/flowStatusReport';

interface IFlowStatusReportRow {
  date: string;
  flowModelName: string;
  email: string;
  counts: IFlowStatusReportCounts;
}

interface IFlowStatusReportCounts {
  started: number;
  inProgress: number;
  withdrawn: number;
  completed: number;
  rejected: number;
}

class FlowStatusReportComponentController implements IController {
  static $inject = ['dateService', '$timeout'];
  // bindings
  public data: IFlowStatusReport;

  public reportRows: IFlowStatusReportRow[];
  public clipboard: string;

  constructor(
    private dateService: DateService,
    private $timeout: ITimeoutService
  ) {
    this.reportRows = [];
  }

  $onInit() {
    const report = {};
    if (!this.data) {
      return;
    }
    for (const flow of this.data.flows) {
      const localDate = this.dateService.formatUtcToLocalForPrinting(
        flow.startDateUtc,
        'YYYY-MM-DD'
      );
      const dateReport = report[localDate] || {};
      report[localDate] = dateReport;
      const flowModelReport = dateReport[flow.flowModelName] || {};
      dateReport[flow.flowModelName] = flowModelReport;
      const userReport = flowModelReport[flow.startedByUserEmail] || {
        started: 0,
        inProgress: 0,
        withdrawn: 0,
        completed: 0,
        rejected: 0
      };
      flowModelReport[flow.startedByUserEmail] = userReport;
      userReport.started++;
      if (flow.finaliseReason === WorkflowFinaliseReason.Withdrawn) {
        userReport.withdrawn++;
      } else if (flow.finaliseReason === WorkflowFinaliseReason.Rejected) {
        userReport.rejected++;
      } else if (flow.finaliseReason === WorkflowFinaliseReason.Completed) {
        userReport.completed++;
      } else {
        userReport.inProgress++;
      }
    }

    for (const date in report) {
      for (const flowModelName in report[date]) {
        for (const email in report[date][flowModelName]) {
          this.reportRows.push({
            date: date,
            flowModelName: flowModelName,
            email: email,
            counts: report[date][flowModelName][email]
          });
        }
      }
    }
    this.$timeout(() => {
      // Delay until HTML is rendered
      this.clipboard = document.getElementById('flow-status-report').outerHTML;
    }, 100);
  }
}

class FlowStatusReportComponent implements IComponentOptions {
  public bindings: Bindings;

  constructor() {
    this.bindings = {
      data: '<'
    };
  }
  transclude = false;
  controller = FlowStatusReportComponentController;
  template = `
  <span flow-clipboard-copy="$ctrl.clipboard"></span>
  <table id='flow-status-report'>
    <thead>
      <tr>
        <th>Date Started</th>
        <th>Flow Model</th>
        <th>Started by User</th>
        <th>Started</th>
        <th>In Progress</th>
        <th>Completed</th>
        <th>Withdrawn</th>
        <th>Rejected</th>
      </tr>
    </thead>
    <tbody>
      <tr ng-repeat="row in $ctrl.reportRows | orderBy:['date','flowModelName','email']">
        <td>{{row.date}}</td>
        <td>{{row.flowModelName}}</td>
        <td>{{row.email}}</td>
        <td>{{row.counts.started}}</td>
        <td>{{row.counts.inProgress}}</td>
        <td>{{row.counts.completed}}</td>
        <td>{{row.counts.withdrawn}}</td>
        <td>{{row.counts.rejected}}</td>
      </tr>
    </tbody>
  </table>
        `;
}

angular
  .module('flowingly.runner.maintenance')
  .component('flowStatusReport', new FlowStatusReportComponent());
