/**
 * @ngdoc type
 * @name RoleDialogController
 * @module flowingly.runner.setup
 * @description This controller is used to control add/edit Role modal dialog
 */
'use strict';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';
import angular from 'angular';

class RoleDialogController {
  static $inject = [
    '$scope',
    'APP_CONFIG',
    'roleApiService',
    'notificationService',
    'permissionsService',
    'flowinglyConstants',
    'guidService'
  ];

  private role = {
    id: null,
    name: '',
    description: '',
    businessId: null,
    isPublic: false,
    isDefault: false,
    permissions: []
  };
  private isNewRole: boolean;
  private modalTitle: string;
  private isFormReadOnly: boolean;
  private permissions;
  private groupedPermissions;

  constructor(
    private $scope,
    private APP_CONFIG,
    private roleApiService,
    private notificationService,
    private permissionsService,
    private flowinglyConstants,
    private guidService
  ) {
    angular.extend(this.role, this.$scope.ngDialogData.role);
    this.isNewRole = this.role.id == this.guidService.empty();
    this.isFormReadOnly =
      this.role.isDefault ||
      (!this.isNewRole &&
        !this.permissionsService.currentUserHasPermission(
          this.flowinglyConstants.permissions.SETUP_ROLE_UPDATE
        )) ||
      (this.isNewRole &&
        !this.permissionsService.currentUserHasPermission(
          this.flowinglyConstants.permissions.SETUP_ROLE_ADD
        ));
    this.modalTitle = this.isNewRole
      ? 'Add'
      : this.isFormReadOnly
      ? 'View'
      : 'Edit';

    this.roleApiService.getPermissions().then((permissions) => {
      this.role.permissions.forEach((rolePermission) => {
        const permission = permissions.find((p) => p.id === rolePermission.id);
        if (permission) {
          permission.selected = true;
        }
      });
      this.permissions = permissions.sort((p1, p2) =>
        p1.name.localeCompare(p2.name)
      );
      this.groupedPermissions = this.groupPermissions(permissions);
    });
  }

  saveRole() {
    if (this.isFormReadOnly) {
      return;
    }

    this.role.permissions = this.permissions.filter(
      (permission) => permission.selected
    );
    this.roleApiService.saveRole(this.role).then(
      () => {
        this.$scope.closeThisDialog(true);
        this.notificationService.showSuccessToast('Role saved');
      },
      () => {
        this.notificationService.showErrorToast('Error while saving Role');
      }
    );
  }

  closeDialog() {
    this.$scope.closeThisDialog();
  }

  private groupPermissions(permissions) {
    const groups = {};
    permissions.forEach((permission) => {
      this.nestPermission(permission.name.split(' - '), permission, groups);
    });
    return groups;
  }

  private nestPermission(codeParts: Array<string>, permission, group) {
    if (codeParts.length === 1) {
      group['permissions'] = group['permissions'] || [];
      permission.displayName = codeParts[0];
      group['permissions'].push(permission);
      return;
    }

    const part = codeParts.shift();
    group[part] = group[part] || {};
    this.nestPermission(codeParts, permission, group[part]);
  }
}

angular
  .module('flowingly.runner.setup')
  .controller('roleDialogController', RoleDialogController);
