(function () {
  'use strict';

  angular
    .module('flowingly.runner.setup')
    .controller('categoryDialogController', categoryDialogController);

  categoryDialogController.$inject = [
    '$scope',
    'APP_CONFIG',
    'sessionService',
    'categoryApiService',
    'notificationService',
    'flowinglyConstants'
  ];

  function categoryDialogController(
    $scope,
    APP_CONFIG,
    sessionService,
    categoryApiService,
    notificationService,
    flowinglyConstants
  ) {
    let ctrl = this;

    ctrl.addEditCategory = addEditCategory;
    ctrl.category = angular.copy($scope.ngDialogData, {});
    ctrl.selectedApprover = {
      id: ctrl.category?.approverId
    };
    ctrl.closeDialog = closeDialog;

    ctrl.errorOnSubmit = false;
    ctrl.errorMessagesOnSubmit = '';
    ctrl.heading = '';
    ctrl.approverPermissions = [
      flowinglyConstants.permissions.GOVERNANCE_FLOWMODEL_APPROVER_CANDIDATE
    ];

    ctrl.showApprover = APP_CONFIG.enableProcessApprovals;

    let isFlowModelAdmin = sessionService.isFlowModelAdmin();

    ////////// PUBLIC METHODS

    function addEditCategory() {
      if (!!APP_CONFIG.disableAdminCategoryEdit && isFlowModelAdmin) return;

      ctrl.category.approverId = ctrl.selectedApprover?.id;
      if (ctrl.category.id === undefined) {
        addCategory();
      } else {
        editCategory();
      }
    }

    function closeDialog() {
      $scope.closeThisDialog();
    }

    ///////// PRIVATAE METHODS
    function addCategory() {
      categoryApiService.addCategory(ctrl.category).then(
        function (response) {
          if (response.data.success) {
            $scope.closeThisDialog(true);
            notificationService.showSuccessToast('Category Created');
          } else {
            showServerReturnedError(response.data.errorMessage);
          }
        },
        function (error) {
          ctrl.errorOnSubmit = true;
          ctrl.errorMessagesOnSubmit = error.data.ExceptionMessage;
          notificationService.showErrorToast('Error in creating new category');
        }
      );
    }

    function editCategory() {
      categoryApiService.editCategory(ctrl.category).then(
        function (response) {
          if (response.data.success) {
            $scope.closeThisDialog(true);
            notificationService.showSuccessToast('Category saved');
          } else {
            showServerReturnedError(response.data.errorMessage);
          }
        },
        function (error) {
          ctrl.errorOnSubmit = true;
          ctrl.errorMessagesOnSubmit = error.data.ExceptionMessage;
          notificationService.showErrorToast('Error in saving category');
        }
      );
    }

    function showServerReturnedError(errorMessage) {
      ctrl.errorOnSubmit = true;
      ctrl.errorMessagesOnSubmit = errorMessage;
    }
  }
})();
