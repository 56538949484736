/**
 * @ngdoc type
 * @module flowingly.runner.flows.imin
 * @name runnerFlowsIminController
 *
 * @description
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { DashboardFilterItem } from '@Client/runner.dashboard.filter/models/runner.dashboard.filter.item.model';
import { FlowsImInFilterTabs } from '@Shared.Angular/flowingly.services/flowingly.constants';
import angular, { IScope, ITimeoutService } from 'angular';

angular
  .module('flowingly.runner.flows.imin')
  .controller('runnerFlowsIminController', runnerFlowsIminController);

runnerFlowsIminController.$inject = [
  '$scope',
  'flowListManager',
  'authService',
  'pubsubService',
  'devLoggingService',
  '$timeout',
  'APP_CONFIG',
  'permissionsService',
  'flowinglyConstants',
  'appInsightsService',
  'momentService',
  'notificationService'
];

function runnerFlowsIminController(
  $scope: IScope,
  flowListManager: FlowListManager,
  authService: AuthService,
  pubsubService: SharedAngular.PubSubService,
  logger: SharedAngular.DevLoggingService,
  $timeout: ITimeoutService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  permissionsService: SharedAngular.PermissionsService,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  appInsightsService: SharedAngular.AppInsightsService,
  moment: Moment,
  notificationService: SharedAngular.NotificationService
) {
  const ctrl = this;
  const dateFilterFormat = 'dd/MM/yyyy';
  ctrl.$onInit = () => {
    init();
  };
  let flowsImInStartedCount = 0;
  let flowsImInDueSoonCount = 0;
  let flowsImInDueTodayCount = 0;
  let flowsImInOverDueCount = 0;
  let flowsImInCompletedCount = 0;
  let flowsImInRejectedCount = 0;
  let flowsImInInProgressCount = 0;

  //-----------------PRIVATE METHODS----------------------------
  function init() {
    const user = authService.getUser();
    ctrl.userId = user.id;
    ctrl.canStartFlows = permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.FLOW_START
    );
    ctrl.groups = flowListManager.groupedFlowsImin;
    ctrl.categories = [];
    ctrl.dashboardFilterItemsCollection = {};
    ctrl.selectedFilterItem = FlowsImInFilterTabs[FlowsImInFilterTabs.STARTED];
    ctrl.showDefaultMessage = showDefaultMessage;
    ctrl.changeCategoryFilter = changeCategoryFilter;
    ctrl.changedFlowFilter = changedFlowFilter;
    ctrl.selectedCategory = flowListManager.getIminFlowCategory();
    ctrl.allCategoriesId = 'all-categories';
    ctrl.categoryClicked = categoryClicked;
    ctrl.tabStrip = null;
    ctrl.initiating = true;
    ctrl.showSubCategories = false;
    ctrl.showDashboardsV1 = APP_CONFIG.showDashboardsV1;
    ctrl.dashBoardFilterChanged = dashBoardFilterChanged;
    ctrl.selectedIndex = flowListManager.getIminFlowCategoryIndex();
    ctrl.selectedName = flowListManager.getIminFlowCategoryName();
    ctrl.pageDescription = 'A list of all flows started by you.';
    ctrl.startDatePickerOpts = {
      format: dateFilterFormat,
      disableDates: startDatePickerDisableDates
    };
    ctrl.endDatePickerOpts = {
      format: dateFilterFormat,
      disableDates: endDatePickerDisableDates
    };
    ctrl.changeStartDateFilter = changeStartDateFilter;
    ctrl.changeEndDateFilter = changeEndDateFilter;

    $scope.$watch('ctrl.initiating', () => {
      if (!ctrl.initiating) {
        appInsightsService.trackMetricIfTimerExist('flowsInEntered');
        getFlowCountForDashboard();
      }
    });

    ctrl.showSubCategories = APP_CONFIG.enableSubCategories;

    ctrl.tabOptions = {
      scrollable: false,
      select: function (e) {
        let changed = false;

        if (e.item.innerText.trim() === 'All flows') {
          if (flowListManager.getOnlyStartedByMe()) {
            flowListManager.setOnlyStartedByMe(false);
            changed = true;
          }

          // set page description and help link
          ctrl.pageDescription = 'A list of all flows you are involved in.';
        } else {
          if (!flowListManager.getOnlyStartedByMe()) {
            flowListManager.setOnlyStartedByMe(true);
            changed = true;
          }

          // set page description and help link
          ctrl.pageDescription = 'A list of all flows started by you.';
        }
        if (changed) {
          refreshFlows().then(function () {
            selectedCategoryOption();
          });
        }
      }
    };

    if (ctrl.groups.length === 0) {
      logger.log('Imin controller: no imin flows, so go to API to get them.');
      refreshFlows().then(() => {
        selectedCategoryOption();
      });
    } else {
      ctrl.categories = angular.copy(flowListManager.flowImInCategories);
      initializeDashboardFilters();
      flowListManager.applyFlowIminFilter();
      ctrl.initiating = false;
      setFilterOptions();
      $scope.$applyAsync(() => {
        selectedCategoryOption();
      });
    }

    const flowsImInSubscriberId = 'runnerFlowsIminController';

    pubsubService.subscribe(
      'SIGNALR_WORKFLOW_NAME_CHANGED',
      onFlowModelNameChanged,
      flowsImInSubscriberId
    );

    if (ctrl.showSubCategories) {
      pubsubService.subscribe(
        'SIGNALR_WORKFLOW_PUBLISHED',
        refreshFlowInstances,
        flowsImInSubscriberId
      );
      pubsubService.subscribe(
        'SIGNALR_WORKFLOW_UNPUBLISHED',
        refreshFlowInstances,
        flowsImInSubscriberId
      );
    }
    $scope.$on('$destroy', () => {
      pubsubService.unsubscribeAll(flowsImInSubscriberId);
    });

    $scope.$on('kendoWidgetCreated', function (ev, widget) {
      if (widget === ctrl.tabStrip) {
        const onlyStartedByMe = flowListManager.getOnlyStartedByMe();

        if (onlyStartedByMe && ctrl.canStartFlows) {
          ctrl.tabStrip.select(0);
        } else {
          ctrl.tabStrip.select(1);
        }
      }
    });
  }

  function startDatePickerDisableDates(date) {
    const today = moment();
    return moment(date).isAfter(today);
  }

  function endDatePickerDisableDates(date) {
    const today = moment();
    return moment(date).isAfter(today);
  }

  function initializeDashboardFilters() {
    ctrl.dashboardFilterItemsCollection = angular.copy({
      selectedFilterItem: { name: ctrl.selectedFilterItem },
      dashboardFilterItems: [
        {
          name: FlowsImInFilterTabs[FlowsImInFilterTabs.STARTED],
          text: 'All',
          toolTipText: 'Flows started',
          count: flowsImInStartedCount,
          cssStyle: 'all-flows-filter-text-color'
        },
        {
          name: FlowsImInFilterTabs[FlowsImInFilterTabs.IN_PROGRESS],
          text: 'In progress',
          toolTipText: 'Flows that are in progress',
          count: flowsImInInProgressCount,
          cssStyle: 'started-filter-text-color'
        },
        {
          name: FlowsImInFilterTabs[FlowsImInFilterTabs.DUE_SOON],
          text: 'Due soon',
          toolTipText: 'Flows that are due soon',
          count: flowsImInDueSoonCount,
          cssStyle: 'due-soon-filter-text-color'
        },
        {
          name: FlowsImInFilterTabs[FlowsImInFilterTabs.DUE_TODAY],
          text: 'Due today',
          toolTipText: 'Flows that are due today',
          count: flowsImInDueTodayCount,
          cssStyle: 'due-today-filter-text-color'
        },
        {
          name: FlowsImInFilterTabs[FlowsImInFilterTabs.OVERDUE],
          text: 'Overdue',
          toolTipText: 'Flows that are overdue',
          count: flowsImInOverDueCount,
          cssStyle: 'overdue-filter-text-color'
        },
        {
          name: FlowsImInFilterTabs[FlowsImInFilterTabs.COMPLETED],
          text: 'Completed',
          toolTipText: 'Flows that are completed',
          count: flowsImInCompletedCount,
          cssStyle: 'completed-filter-text-color'
        },
        {
          name: FlowsImInFilterTabs[FlowsImInFilterTabs.REJECTED],
          text: 'Rejected',
          toolTipText: 'Flows that are rejected',
          count: flowsImInRejectedCount,
          cssStyle: 'rejected-flows-filter-text-color'
        }
      ]
    });
  }
  // After retrieving the flow list from server wide we need to reset the Flow Filter DropDown and the Flow Status Dropdown
  function setFilterOptions() {
    setFlowFilters();
    ctrl.statusOptions = flowListManager.statusOptions;
    ctrl.selectedFlowFilter = flowListManager.getIminFlowFilter();
    ctrl.selectedStatusOption = flowListManager.getIminStatus();
    ctrl.selectedStartDate = flowListManager.getIminStartDateFilter();
    ctrl.selectedEndDate = flowListManager.getIminEndDateFilter();
  }
  // truncate lengthy flownames for displaying flow filter dropdown to fit in the screen. tooltip will show full flow name
  function setFlowFilters() {
    ctrl.flowFilters = [];
    const maxchars = APP_CONFIG.flowFilterMaxCharacters;
    flowListManager.getIminFlowFilters().forEach((filter) => {
      let fname = filter.name;
      if (fname.length > maxchars) {
        fname = fname.substring(0, maxchars) + '...';
      }
      const flowFilter = {
        id: filter.id,
        name: fname,
        title: filter.name
      };
      ctrl.flowFilters.push(flowFilter);
    });
  }

  function refreshFlows() {
    return flowListManager.refreshFlowsImin().then(function () {
      ctrl.categories = angular.copy(flowListManager.flowImInCategories);
      setFilterOptions();
      getFlowCountForDashboard();
      ctrl.initiating = false;
    });
  }

  function refreshFlowInstances() {
    return flowListManager.refreshFlowInstanceLists().then(function () {
      ctrl.categories = angular.copy(flowListManager.flowImInCategories);
      setFilterOptions();
      getFlowCountForDashboard();
      ctrl.initiating = false;
    });
  }

  function onFlowModelNameChanged(msg, data) {
    const msgdata = JSON.parse(data);
    flowListManager.updateFlowNames(msgdata);
    ctrl.groups = flowListManager.groupedFlowsImin;
  }

  //-----------------PUBLIC METHODS----------------------------
  ctrl.changeStatusOption = function () {
    flowListManager.setIminStatus(ctrl.selectedStatusOption);
    //refreshing the categories will unselect the highlighted category because ctrl.categories will get modified in category refresh
    refreshFlows().then(() => {
      selectedCategoryOption();
    });
  };

  function isDateFiltersValid() {
    const dateFormat = flowListManager.getIminDateFilterFormat();
    const startDateStr = flowListManager.getIminStartDateFilter();
    const endDateStr = flowListManager.getIminEndDateFilter();

    const dateFormatRegex = /^\d{2}\/\d{2}\/[1-9]\d{3}$/;

    if (
      !dateFormatRegex.test(startDateStr.trim()) ||
      !dateFormatRegex.test(endDateStr.trim())
    ) {
      notificationService.showErrorToast(
        'One or both date filters are in an invalid format'
      );
      return false;
    }

    const startDate = moment(startDateStr, dateFormat, true);
    const endDate = moment(endDateStr, dateFormat, true);

    if (!startDate.isValid() || !endDate.isValid()) {
      notificationService.showErrorToast(
        'One or both date filters are in an invalid format'
      );
      return false;
    }

    const currentDate = moment().startOf('day');

    if (endDate.isBefore(startDate)) {
      notificationService.showErrorToast(
        'End date must be later than start date'
      );
      return false;
    }

    if (endDate.isAfter(currentDate)) {
      notificationService.showErrorToast('End date must not be in the future');
      return false;
    }

    const monthDiffAllowed = APP_CONFIG.flowsInDateFilterMaxRange;
    const maxEndDate = moment(startDate).add(monthDiffAllowed, 'months');
    if (endDate.isAfter(maxEndDate)) {
      notificationService.showErrorToast(
        `Date range must not exceed ${monthDiffAllowed} month(s)`
      );
      return false;
    }
    return true;
  }

  function changeStartDateFilter() {
    flowListManager.setIminStartDateFilter(ctrl.selectedStartDate);
    if (!isDateFiltersValid()) {
      return;
    }

    refreshFlows();
  }

  function changeEndDateFilter() {
    flowListManager.setIminEndDateFilter(ctrl.selectedEndDate);
    if (!isDateFiltersValid()) {
      return;
    }

    refreshFlows();
  }

  function changedFlowFilter() {
    flowListManager.setIminFlowFilter(ctrl.selectedFlowFilter);
    flowListManager.applyFlowIminFilter();

    const visibleFlows = ctrl.groups.filter((group) => group.show);
    if (visibleFlows && visibleFlows.length > 0) {
      // All the visible flows are for the same flow model which belongs to one flow category.
      //here we dont need to set the flow filter to default. so defaultFlowFilterRequired is set to false
      flowListManager.setIminFlowCategory(
        visibleFlows[0].FlowCategoryId,
        false
      );
      selectedCategoryOption();
    }
    getFlowCountForDashboard();
  }

  function changeCategoryFilter() {
    flowListManager.setIminFlowCategory(ctrl.selectedCategory);
    setFilterOptions();
    flowListManager.applyFlowIminFilter();
    getFlowCountForDashboard();
  }

  function showDefaultMessage() {
    const hasVisibleGroup = ctrl.groups.some((g) => {
      return g.show;
    });

    return !hasVisibleGroup;
  }

  function categoryClicked(categoryId, categoryIndex, categoryName) {
    if (ctrl.showSubCategories) {
      flowListManager.setIminFlowCategoryIndex(categoryIndex);
      flowListManager.setIminFlowCategoryName(categoryName);
      ctrl.selectedCategory =
        categoryId !== ctrl.allCategoriesId ? categoryId : null; // All Categories.

      highlightSelectedCategory(ctrl.selectedCategory || ctrl.allCategoriesId);
    } else {
      const category = ctrl.categories.find(({ name }) => name === categoryId);
      ctrl.selectedCategory = category ? category.id : null; // All Categories.

      highlightSelectedCategory(
        category ? category.name : ctrl.allCategoriesId
      );
    }
    changeCategoryFilter();
  }

  function selectedCategoryOption() {
    if (ctrl.showSubCategories) {
      selectedCategoryOptionCategoriesWithSubCategories();
    } else {
      const category = ctrl.categories.find(
        ({ id }) => id === flowListManager.getIminFlowCategory()
      );
      if (category) {
        highlightSelectedCategory(category.name);
      } else {
        highlightSelectedCategory(ctrl.allCategoriesId);
      }
    }
  }

  function highlightSelectedCategory(categoryName) {
    const selectedCategoryClassName = 'selected-category';
    // First unselect all.
    const allSelected = document.getElementsByClassName(
      selectedCategoryClassName
    );
    if (allSelected) {
      Array.from(allSelected).forEach((elem) => {
        elem.classList.remove(selectedCategoryClassName);
      });
    }
    // Select the category.
    if (categoryName != null && categoryName !== '') {
      const categoryNameElement = document.getElementById(categoryName);
      if (categoryNameElement) {
        categoryNameElement.classList.toggle(selectedCategoryClassName);
      }
    }
    ctrl.selectedIndex = flowListManager.getIminFlowCategoryIndex();
    ctrl.selectedName = flowListManager.getIminFlowCategoryName();
  }

  function findCategoryIndex(categories, categoryId, index = '') {
    for (let i = 0; i < categories.length; i++) {
      const currentPath = `${index}_${i}`;

      if (categories[i].id === categoryId) {
        return currentPath.substring(1); // Remove the leading underscore
      }

      if (categories[i].items) {
        const result = findCategoryIndex(
          categories[i].items,
          categoryId,
          currentPath
        );
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  function selectedCategoryOptionCategoriesWithSubCategories() {
    let index = findCategoryIndex(
      ctrl.categories,
      flowListManager.getIminFlowCategory()
    );
    index = index == null ? '0' : index;
    flowListManager.setIminFlowCategoryIndex(index);
    if (index === '0') {
      categoryClicked(ctrl.allCategoriesId, '0', 'All');
    } else {
      highlightSelectedCategory(null);
    }
  }

  function dashBoardFilterChanged(filterItem: DashboardFilterItem) {
    flowListManager.setImInDashboardFilter(filterItem.name);
    flowListManager.applyFlowIminFilter();
    ctrl.selectedFilterItem =
      FlowsImInFilterTabs[FlowsImInFilterTabs[filterItem.name]];
  }
  function getFlowCountForDashboard() {
    flowsImInStartedCount = flowListManager.getIminStartedFlowCount();
    flowsImInDueSoonCount = flowListManager.getIminDueSoonFlowCount();
    flowsImInDueTodayCount = flowListManager.getIminDueTodayFlowCount();
    flowsImInOverDueCount = flowListManager.getIminOverdueFlowCount();
    flowsImInCompletedCount = flowListManager.getIminCompletedFlowCount();
    flowsImInRejectedCount = flowListManager.getIminRejectedFlowCount();
    flowsImInInProgressCount = flowListManager.getIminInProgressFlowCount();
    initializeDashboardFilters();
  }
}
