/**
 * @ngdoc directive
 * @name usersListActions
 * @module flowingly.runner.setup
 * @description This component is used to display actions button list in a users row
 * @usage
 * ```
     <users-list-actions user-id='dataItem.id'></users-list-actions>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * userId: id of user to act upon
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('usersListActions', {
    bindings: {
      user: '<',
      onUsersUpdated: '&'
    },
    templateUrl:
      'Client/runner.setup/runner.setup.users/runner.setup.users.list.actions.tmpl.html',
    controller: [
      '$scope',
      'dialogService',
      'notificationService',
      'userApiService',
      'sessionService',
      'setupDeleteApiService',
      function (
        $scope,
        dialogService,
        notificationService,
        userApiService,
        sessionService,
        setupDeleteApiService
      ) {
        var $ctrl = this;

        const user = 1;

        $ctrl.editClick = editClick;
        $ctrl.deleteClick = deleteClick;

        function editClick() {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.users/runner.setup.users.dialog.tmpl.html',
              controller: 'userDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: $ctrl.user
            })
            .then(function (result) {
              // if result is boolean, true means user updated, false means edit user is cancelled
              if (
                result === false ||
                (result !== undefined &&
                  dialogService.isCloseModalWithCancelAction(result))
              ) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }
              //raise an 'event' to notify the parent
              $ctrl.onUsersUpdated();
            });
        }

        function deleteClick() {
          var currentUserEmail = sessionService.getUser().email;
          const userToDelete = {
            email: $ctrl.user.email,
            id: $ctrl.user.id,
            fullName: $ctrl.user.fullName,
            isManager: $ctrl.user.isManager
          };
          setupDeleteApiService.tryDeleteUser(
            userToDelete,
            currentUserEmail,
            $scope,
            $ctrl.onUsersUpdated
          );
        }
      }
    ]
  });
})();
