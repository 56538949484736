import angular from 'angular';

angular.module('flowingly.runner.maintenance').config(config);
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.maintenance.reports', {
    url: '/reports',
    params: { title: 'Maintenance - Reports' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/reports/runner.maintenance.reports.tmpl.html',
        controllerAs: '$ctrl',
        controller: 'maintenanceReportsController'
      }
    }
  });
}
